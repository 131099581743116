/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Acquirer,
    AcquirerFromJSON,
    AcquirerFromJSONTyped,
    AcquirerToJSON,
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    BankAccount,
    BankAccountFromJSON,
    BankAccountFromJSONTyped,
    BankAccountToJSON,
    ImagePos,
    ImagePosFromJSON,
    ImagePosFromJSONTyped,
    ImagePosToJSON,
    Logo,
    LogoFromJSON,
    LogoFromJSONTyped,
    LogoToJSON,
    Merchant,
    MerchantFromJSON,
    MerchantFromJSONTyped,
    MerchantToJSON,
    PaymentCalculationRules,
    PaymentCalculationRulesFromJSON,
    PaymentCalculationRulesFromJSONTyped,
    PaymentCalculationRulesToJSON,
    ProtocolCustomizations,
    ProtocolCustomizationsFromJSON,
    ProtocolCustomizationsFromJSONTyped,
    ProtocolCustomizationsToJSON,
    ReportCustomization,
    ReportCustomizationFromJSON,
    ReportCustomizationFromJSONTyped,
    ReportCustomizationToJSON,
    Responsible,
    ResponsibleFromJSON,
    ResponsibleFromJSONTyped,
    ResponsibleToJSON,
    SalePlan,
    SalePlanFromJSON,
    SalePlanFromJSONTyped,
    SalePlanToJSON,
    SellerAndroidReceipt,
    SellerAndroidReceiptFromJSON,
    SellerAndroidReceiptFromJSONTyped,
    SellerAndroidReceiptToJSON,
    SellerCheckouts,
    SellerCheckoutsFromJSON,
    SellerCheckoutsFromJSONTyped,
    SellerCheckoutsToJSON,
    SellerEmails,
    SellerEmailsFromJSON,
    SellerEmailsFromJSONTyped,
    SellerEmailsToJSON,
    Theme,
    ThemeFromJSON,
    ThemeFromJSONTyped,
    ThemeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    document: string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    document_ofac?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    document_onu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    document_rf?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    state_registration?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    is_active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    created_at?: string;
    /**
     * 
     * @type {Date}
     * @memberof Seller
     */
    updated_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    settlement_type: SellerSettlementTypeEnum;
    /**
     * 
     * @type {Address}
     * @memberof Seller
     */
    address: Address;
    /**
     * 
     * @type {BankAccount}
     * @memberof Seller
     */
    bank_account: BankAccount;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    monthly_billing: number;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    covenant?: string;
    /**
     * 
     * @type {Array<Acquirer>}
     * @memberof Seller
     */
    acquirers?: Array<Acquirer>;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    is_split?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    secondary_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    tertiary_phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    status: SellerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    type: SellerTypeEnum;
    /**
     * 
     * @type {Merchant}
     * @memberof Seller
     */
    merchant: Merchant;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    merchant_id?: string;
    /**
     * 
     * @type {SalePlan}
     * @memberof Seller
     */
    sale_plan: SalePlan;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    sale_plan_id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Seller
     */
    absorb_costs?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    accounting_account_settlement_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    pix_pos?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    billet_provider: SellerBilletProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    is_assignor?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Seller
     */
    no_payment_methods?: Array<string>;
    /**
     * 
     * @type {Array<PaymentCalculationRules>}
     * @memberof Seller
     */
    payment_calculation_rules?: Array<PaymentCalculationRules>;
    /**
     * 
     * @type {Logo}
     * @memberof Seller
     */
    logo?: Logo;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    logo_id?: string;
    /**
     * 
     * @type {ImagePos}
     * @memberof Seller
     */
    image_pos?: ImagePos;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    image_pos_id?: string;
    /**
     * 
     * @type {Theme}
     * @memberof Seller
     */
    theme?: Theme;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    theme_id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    has_split_rules?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    pix_provider?: SellerPixProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    confirmation_payment_limit?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    confirmation_payment_limit_days?: number;
    /**
     * 
     * @type {ProtocolCustomizations}
     * @memberof Seller
     */
    protocol_customizations?: ProtocolCustomizations;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    secondary_email?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    tertiary_email?: string;
    /**
     * 
     * @type {SellerEmails}
     * @memberof Seller
     */
    custom_emails?: SellerEmails;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    is_internal?: boolean;
    /**
     * 
     * @type {ReportCustomization}
     * @memberof Seller
     */
    report_customization?: ReportCustomization;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    billet_code_fee?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    no_spreading_pix?: boolean;
    /**
     * 
     * @type {Array<Responsible>}
     * @memberof Seller
     */
    responsibles?: Array<Responsible>;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    cns?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    assignment?: SellerAssignmentEnum;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    owner_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    owner_document?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    owner_document_ofac?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    owner_document_onu?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    owner_document_pep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    owner_document_ref?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    owner_mother_name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Seller
     */
    owner_birth?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    frontier?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    cnae?: string;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    last_semestral_invoicing?: number;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    temperature?: SellerTemperatureEnum;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    erp?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    marketplace_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    risk_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    min_risk?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    three_ds_active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    min_three_ds?: number;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    three_ds_value?: number;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    risk_value?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    billet_code?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    celcoin_value?: number;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    celcoin_fee_type?: SellerCelcoinFeeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    card_payer_check?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    card_payer_check_min_value?: number;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    extract_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    accreditation_status?: SellerAccreditationStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    registered_omie?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    payment_provider?: SellerPaymentProviderEnum;
    /**
     * 
     * @type {SellerCheckouts}
     * @memberof Seller
     */
    custom_checkout?: SellerCheckouts;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    custom_payment_link_checkout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    form_link_extended?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    has_digital_account?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    pos_android_cancel?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    pos_android_absorb_costs?: boolean;
    /**
     * 
     * @type {SellerAndroidReceipt}
     * @memberof Seller
     */
    custom_android_receipt?: SellerAndroidReceipt;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    digital_key_pix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    inactivity_alert?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    pos_android_refund_sale?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SellerSettlementTypeEnum {
    Pix = 'PIX',
    Ted = 'TED'
}/**
* @export
* @enum {string}
*/
export enum SellerStatusEnum {
    Activated = 'activated',
    Inactive = 'inactive',
    Pending = 'pending',
    ActivationSent = 'activation_sent'
}/**
* @export
* @enum {string}
*/
export enum SellerTypeEnum {
    Transaction = 'transaction',
    Registry = 'registry',
    Group = 'group',
    RestrictedSeller = 'restricted_seller',
    Creditor = 'creditor'
}/**
* @export
* @enum {string}
*/
export enum SellerBilletProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau',
    BtgDigital = 'btg_digital',
    Btg = 'btg'
}/**
* @export
* @enum {string}
*/
export enum SellerPixProviderEnum {
    Adyen = 'adyen',
    Bradesco = 'bradesco',
    Itau = 'itau',
    BtgDigital = 'btg_digital',
    Btg = 'btg'
}/**
* @export
* @enum {string}
*/
export enum SellerAssignmentEnum {
    LegalEntity = 'legal_entity',
    Civil = 'civil',
    Protest = 'protest',
    Note = 'note',
    Properties = 'properties'
}/**
* @export
* @enum {string}
*/
export enum SellerTemperatureEnum {
    SuperCold = 'super_cold',
    Cold = 'cold',
    Warm = 'warm',
    Hot = 'hot',
    SuperHot = 'super_hot'
}/**
* @export
* @enum {string}
*/
export enum SellerCelcoinFeeTypeEnum {
    Percentage = 'percentage',
    Real = 'real'
}/**
* @export
* @enum {string}
*/
export enum SellerAccreditationStatusEnum {
    Active = 'active',
    ActiveAndOwnershipChange = 'active_and_ownership_change',
    Cancelled = 'cancelled',
    InactiveByOwnershipChange = 'inactive_by_ownership_change'
}/**
* @export
* @enum {string}
*/
export enum SellerPaymentProviderEnum {
    Adyen = 'adyen',
    Rede = 'rede'
}

export function SellerFromJSON(json: any): Seller {
    return SellerFromJSONTyped(json, false);
}

export function SellerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Seller {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': json['description'],
        'document': json['document'],
        'document_ofac': !exists(json, 'document_ofac') ? undefined : json['document_ofac'],
        'document_onu': !exists(json, 'document_onu') ? undefined : json['document_onu'],
        'document_rf': !exists(json, 'document_rf') ? undefined : json['document_rf'],
        'state_registration': !exists(json, 'state_registration') ? undefined : json['state_registration'],
        'code': json['code'],
        'is_active': !exists(json, 'is_active') ? undefined : json['is_active'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : (new Date(json['updated_at'])),
        'settlement_type': json['settlement_type'],
        'address': AddressFromJSON(json['address']),
        'bank_account': BankAccountFromJSON(json['bank_account']),
        'monthly_billing': json['monthly_billing'],
        'covenant': !exists(json, 'covenant') ? undefined : json['covenant'],
        'acquirers': !exists(json, 'acquirers') ? undefined : ((json['acquirers'] as Array<any>).map(AcquirerFromJSON)),
        'is_split': !exists(json, 'is_split') ? undefined : json['is_split'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'secondary_phone': !exists(json, 'secondary_phone') ? undefined : json['secondary_phone'],
        'tertiary_phone': !exists(json, 'tertiary_phone') ? undefined : json['tertiary_phone'],
        'status': json['status'],
        'type': json['type'],
        'merchant': MerchantFromJSON(json['merchant']),
        'merchant_id': !exists(json, 'merchant_id') ? undefined : json['merchant_id'],
        'sale_plan': SalePlanFromJSON(json['sale_plan']),
        'sale_plan_id': !exists(json, 'sale_plan_id') ? undefined : json['sale_plan_id'],
        'absorb_costs': !exists(json, 'absorb_costs') ? undefined : json['absorb_costs'],
        'accounting_account_settlement_id': json['accounting_account_settlement_id'],
        'pix_pos': !exists(json, 'pix_pos') ? undefined : json['pix_pos'],
        'billet_provider': json['billet_provider'],
        'is_assignor': !exists(json, 'is_assignor') ? undefined : json['is_assignor'],
        'no_payment_methods': !exists(json, 'no_payment_methods') ? undefined : json['no_payment_methods'],
        'payment_calculation_rules': !exists(json, 'payment_calculation_rules') ? undefined : ((json['payment_calculation_rules'] as Array<any>).map(PaymentCalculationRulesFromJSON)),
        'logo': !exists(json, 'logo') ? undefined : LogoFromJSON(json['logo']),
        'logo_id': !exists(json, 'logo_id') ? undefined : json['logo_id'],
        'image_pos': !exists(json, 'image_pos') ? undefined : ImagePosFromJSON(json['image_pos']),
        'image_pos_id': !exists(json, 'image_pos_id') ? undefined : json['image_pos_id'],
        'theme': !exists(json, 'theme') ? undefined : ThemeFromJSON(json['theme']),
        'theme_id': !exists(json, 'theme_id') ? undefined : json['theme_id'],
        'has_split_rules': !exists(json, 'has_split_rules') ? undefined : json['has_split_rules'],
        'pix_provider': !exists(json, 'pix_provider') ? undefined : json['pix_provider'],
        'confirmation_payment_limit': !exists(json, 'confirmation_payment_limit') ? undefined : json['confirmation_payment_limit'],
        'confirmation_payment_limit_days': !exists(json, 'confirmation_payment_limit_days') ? undefined : json['confirmation_payment_limit_days'],
        'protocol_customizations': !exists(json, 'protocol_customizations') ? undefined : ProtocolCustomizationsFromJSON(json['protocol_customizations']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'secondary_email': !exists(json, 'secondary_email') ? undefined : json['secondary_email'],
        'tertiary_email': !exists(json, 'tertiary_email') ? undefined : json['tertiary_email'],
        'custom_emails': !exists(json, 'custom_emails') ? undefined : SellerEmailsFromJSON(json['custom_emails']),
        'is_internal': !exists(json, 'is_internal') ? undefined : json['is_internal'],
        'report_customization': !exists(json, 'report_customization') ? undefined : ReportCustomizationFromJSON(json['report_customization']),
        'billet_code_fee': !exists(json, 'billet_code_fee') ? undefined : json['billet_code_fee'],
        'no_spreading_pix': !exists(json, 'no_spreading_pix') ? undefined : json['no_spreading_pix'],
        'responsibles': !exists(json, 'responsibles') ? undefined : ((json['responsibles'] as Array<any>).map(ResponsibleFromJSON)),
        'cns': !exists(json, 'cns') ? undefined : json['cns'],
        'assignment': !exists(json, 'assignment') ? undefined : json['assignment'],
        'owner_name': !exists(json, 'owner_name') ? undefined : json['owner_name'],
        'owner_document': !exists(json, 'owner_document') ? undefined : json['owner_document'],
        'owner_document_ofac': !exists(json, 'owner_document_ofac') ? undefined : json['owner_document_ofac'],
        'owner_document_onu': !exists(json, 'owner_document_onu') ? undefined : json['owner_document_onu'],
        'owner_document_pep': !exists(json, 'owner_document_pep') ? undefined : json['owner_document_pep'],
        'owner_document_ref': !exists(json, 'owner_document_ref') ? undefined : json['owner_document_ref'],
        'owner_mother_name': !exists(json, 'owner_mother_name') ? undefined : json['owner_mother_name'],
        'owner_birth': !exists(json, 'owner_birth') ? undefined : (new Date(json['owner_birth'])),
        'frontier': !exists(json, 'frontier') ? undefined : json['frontier'],
        'cnae': !exists(json, 'cnae') ? undefined : json['cnae'],
        'last_semestral_invoicing': !exists(json, 'last_semestral_invoicing') ? undefined : json['last_semestral_invoicing'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'erp': !exists(json, 'erp') ? undefined : json['erp'],
        'marketplace_name': !exists(json, 'marketplace_name') ? undefined : json['marketplace_name'],
        'risk_active': !exists(json, 'risk_active') ? undefined : json['risk_active'],
        'min_risk': !exists(json, 'min_risk') ? undefined : json['min_risk'],
        'three_ds_active': !exists(json, 'three_ds_active') ? undefined : json['three_ds_active'],
        'min_three_ds': !exists(json, 'min_three_ds') ? undefined : json['min_three_ds'],
        'three_ds_value': !exists(json, 'three_ds_value') ? undefined : json['three_ds_value'],
        'risk_value': !exists(json, 'risk_value') ? undefined : json['risk_value'],
        'billet_code': !exists(json, 'billet_code') ? undefined : json['billet_code'],
        'celcoin_value': !exists(json, 'celcoin_value') ? undefined : json['celcoin_value'],
        'celcoin_fee_type': !exists(json, 'celcoin_fee_type') ? undefined : json['celcoin_fee_type'],
        'card_payer_check': !exists(json, 'card_payer_check') ? undefined : json['card_payer_check'],
        'card_payer_check_min_value': !exists(json, 'card_payer_check_min_value') ? undefined : json['card_payer_check_min_value'],
        'extract_name': !exists(json, 'extract_name') ? undefined : json['extract_name'],
        'accreditation_status': !exists(json, 'accreditation_status') ? undefined : json['accreditation_status'],
        'registered_omie': !exists(json, 'registered_omie') ? undefined : json['registered_omie'],
        'payment_provider': !exists(json, 'payment_provider') ? undefined : json['payment_provider'],
        'custom_checkout': !exists(json, 'custom_checkout') ? undefined : SellerCheckoutsFromJSON(json['custom_checkout']),
        'custom_payment_link_checkout': !exists(json, 'custom_payment_link_checkout') ? undefined : json['custom_payment_link_checkout'],
        'form_link_extended': !exists(json, 'form_link_extended') ? undefined : json['form_link_extended'],
        'has_digital_account': !exists(json, 'has_digital_account') ? undefined : json['has_digital_account'],
        'pos_android_cancel': !exists(json, 'pos_android_cancel') ? undefined : json['pos_android_cancel'],
        'pos_android_absorb_costs': !exists(json, 'pos_android_absorb_costs') ? undefined : json['pos_android_absorb_costs'],
        'custom_android_receipt': !exists(json, 'custom_android_receipt') ? undefined : SellerAndroidReceiptFromJSON(json['custom_android_receipt']),
        'digital_key_pix': !exists(json, 'digital_key_pix') ? undefined : json['digital_key_pix'],
        'inactivity_alert': !exists(json, 'inactivity_alert') ? undefined : json['inactivity_alert'],
        'pos_android_refund_sale': !exists(json, 'pos_android_refund_sale') ? undefined : json['pos_android_refund_sale'],
    };
}

export function SellerToJSON(value?: Seller | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'document': value.document,
        'document_ofac': value.document_ofac,
        'document_onu': value.document_onu,
        'document_rf': value.document_rf,
        'state_registration': value.state_registration,
        'code': value.code,
        'is_active': value.is_active,
        'created_at': value.created_at,
        'updated_at': value.updated_at === undefined ? undefined : (value.updated_at.toISOString()),
        'settlement_type': value.settlement_type,
        'address': AddressToJSON(value.address),
        'bank_account': BankAccountToJSON(value.bank_account),
        'monthly_billing': value.monthly_billing,
        'covenant': value.covenant,
        'acquirers': value.acquirers === undefined ? undefined : ((value.acquirers as Array<any>).map(AcquirerToJSON)),
        'is_split': value.is_split,
        'phone': value.phone,
        'secondary_phone': value.secondary_phone,
        'tertiary_phone': value.tertiary_phone,
        'status': value.status,
        'type': value.type,
        'merchant': MerchantToJSON(value.merchant),
        'merchant_id': value.merchant_id,
        'sale_plan': SalePlanToJSON(value.sale_plan),
        'sale_plan_id': value.sale_plan_id,
        'absorb_costs': value.absorb_costs,
        'accounting_account_settlement_id': value.accounting_account_settlement_id,
        'pix_pos': value.pix_pos,
        'billet_provider': value.billet_provider,
        'is_assignor': value.is_assignor,
        'no_payment_methods': value.no_payment_methods,
        'payment_calculation_rules': value.payment_calculation_rules === undefined ? undefined : ((value.payment_calculation_rules as Array<any>).map(PaymentCalculationRulesToJSON)),
        'logo': LogoToJSON(value.logo),
        'logo_id': value.logo_id,
        'image_pos': ImagePosToJSON(value.image_pos),
        'image_pos_id': value.image_pos_id,
        'theme': ThemeToJSON(value.theme),
        'theme_id': value.theme_id,
        'has_split_rules': value.has_split_rules,
        'pix_provider': value.pix_provider,
        'confirmation_payment_limit': value.confirmation_payment_limit,
        'confirmation_payment_limit_days': value.confirmation_payment_limit_days,
        'protocol_customizations': ProtocolCustomizationsToJSON(value.protocol_customizations),
        'email': value.email,
        'secondary_email': value.secondary_email,
        'tertiary_email': value.tertiary_email,
        'custom_emails': SellerEmailsToJSON(value.custom_emails),
        'is_internal': value.is_internal,
        'report_customization': ReportCustomizationToJSON(value.report_customization),
        'billet_code_fee': value.billet_code_fee,
        'no_spreading_pix': value.no_spreading_pix,
        'responsibles': value.responsibles === undefined ? undefined : ((value.responsibles as Array<any>).map(ResponsibleToJSON)),
        'cns': value.cns,
        'assignment': value.assignment,
        'owner_name': value.owner_name,
        'owner_document': value.owner_document,
        'owner_document_ofac': value.owner_document_ofac,
        'owner_document_onu': value.owner_document_onu,
        'owner_document_pep': value.owner_document_pep,
        'owner_document_ref': value.owner_document_ref,
        'owner_mother_name': value.owner_mother_name,
        'owner_birth': value.owner_birth === undefined ? undefined : (value.owner_birth.toISOString()),
        'frontier': value.frontier,
        'cnae': value.cnae,
        'last_semestral_invoicing': value.last_semestral_invoicing,
        'temperature': value.temperature,
        'erp': value.erp,
        'marketplace_name': value.marketplace_name,
        'risk_active': value.risk_active,
        'min_risk': value.min_risk,
        'three_ds_active': value.three_ds_active,
        'min_three_ds': value.min_three_ds,
        'three_ds_value': value.three_ds_value,
        'risk_value': value.risk_value,
        'billet_code': value.billet_code,
        'celcoin_value': value.celcoin_value,
        'celcoin_fee_type': value.celcoin_fee_type,
        'card_payer_check': value.card_payer_check,
        'card_payer_check_min_value': value.card_payer_check_min_value,
        'extract_name': value.extract_name,
        'accreditation_status': value.accreditation_status,
        'registered_omie': value.registered_omie,
        'payment_provider': value.payment_provider,
        'custom_checkout': SellerCheckoutsToJSON(value.custom_checkout),
        'custom_payment_link_checkout': value.custom_payment_link_checkout,
        'form_link_extended': value.form_link_extended,
        'has_digital_account': value.has_digital_account,
        'pos_android_cancel': value.pos_android_cancel,
        'pos_android_absorb_costs': value.pos_android_absorb_costs,
        'custom_android_receipt': SellerAndroidReceiptToJSON(value.custom_android_receipt),
        'digital_key_pix': value.digital_key_pix,
        'inactivity_alert': value.inactivity_alert,
        'pos_android_refund_sale': value.pos_android_refund_sale,
    };
}


