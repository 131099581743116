import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Box } from '@chakra-ui/react';
import { ImageType } from 'react-images-uploading';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useTheme } from 'contexts/ThemeProvider';
import { useToasts } from 'react-toast-notifications';

type Props = {
	isOpen: boolean;
	imageToCrop: ImageType | undefined;
	setCroppedImage: (croppedImg: string) => void;
	onClose: () => void;
};

const CropReceiptImageModal: React.FC<Props> = (props) => {
	const { isOpen, imageToCrop, setCroppedImage, onClose } = props;
	const cropperRef = React.useRef<HTMLImageElement>(null);
	const { theme } = useTheme();
	const { addToast } = useToasts();
	const showCroppedImage = React.useCallback(() => {
		try {
			const imageElement: any = cropperRef?.current;
			const cropper: any = imageElement?.cropper;

			const croppedImageBase64 = cropper.getCroppedCanvas().toDataURL();

			setCroppedImage(croppedImageBase64);
			onClose();
		} catch {
			addToast('Não foi possível carregar a imagem', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageToCrop?.dataURL]);

	const onCrop = () => {};

	return (
		<Modal size='lg' closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Ajustar imagem</ModalHeader>
				<ModalCloseButton />

				<ModalBody pb={6}>
					<Box mb='3' justifyContent='center'>
						<Cropper
							style={{ height: 400, width: '100%' }}
							aspectRatio={384 / 284}
							src={imageToCrop?.dataURL}
							viewMode={1}
							minCropBoxHeight={10}
							minCropBoxWidth={10}
							background={false}
							responsive={true}
							autoCropArea={1}
							checkOrientation={false}
							guides={true}
							crop={onCrop}
							ref={cropperRef}
						/>
					</Box>
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose} borderWidth={0.2} borderColor='gray.400'>
						Cancelar
					</Button>

					<Button
						bgColor={theme?.primary_color}
						color={theme?.default_color}
						ml={3}
						_hover={{
							bg: theme?.default_color,
							color: theme?.text_color,
						}}
						onClick={showCroppedImage}
					>
						Salvar Ajuste
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CropReceiptImageModal;
