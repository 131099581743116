/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReceiptAndroidData
 */
export interface ReceiptAndroidData {
    /**
     * 
     * @type {string}
     * @memberof ReceiptAndroidData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptAndroidData
     */
    type: ReceiptAndroidDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReceiptAndroidData
     */
    aligment: string;
}

/**
* @export
* @enum {string}
*/
export enum ReceiptAndroidDataTypeEnum {
    BaseReceipt = 'BaseReceipt',
    SellerName = 'SellerName',
    SaleDescription = 'SaleDescription',
    Logo = 'Logo',
    FooterReceipt = 'FooterReceipt',
    CustomText = 'CustomText',
    CustomerData = 'CustomerData'
}

export function ReceiptAndroidDataFromJSON(json: any): ReceiptAndroidData {
    return ReceiptAndroidDataFromJSONTyped(json, false);
}

export function ReceiptAndroidDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceiptAndroidData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'aligment': json['aligment'],
    };
}

export function ReceiptAndroidDataToJSON(value?: ReceiptAndroidData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'aligment': value.aligment,
    };
}


