export const FieldNames = {
    name: 'Nome',
    document: 'CPF/CNPJ',
    description: 'Descrição',
    state_registration: 'Registro Estadual',
    cns: 'CNS',
    cnae: 'CNAE',
    assignment: 'Atribuição',
    code: 'Chave do Estabelecimento',
    monthly_billing: 'Faturamento Mensal',
    covenant: 'Convênio',
    status: 'Status',
    erp: 'ERP',
    phone: 'Telefone',
    secondary_phone: 'Telefone Secundário',
    tertiary_phone: 'Telefone Terciário',
    email: 'E-mail',
    secondary_email: 'E-mail Secundário',
    tertiary_email: 'E-mail Terciário',
    owner_name: 'Nome do Proprietário',
    owner_document: 'CPF do Proprietário',
    zipcode: 'CEP',
    street: 'Logradouro',
    number: 'Número',
    complement: 'Complemento',
    district: 'Bairro',
    city: 'Cidade',
    state: 'UF',
    bank_code: 'Código do Banco',
    agency_number: 'Número da Agência',
    agency_digit: 'Digito da Agência',
    account_number: 'Número da Conta',
    account_digit: 'Digito da Conta',
    type_account: 'Tipo de Conta',
    pix_key: 'Chave Pix',
    support: 'Suporte',
    commercial: 'Comercial',
    merchant_id: 'Merchant',
    settlement_type: 'Tipo de Liquidação',
    extract_name: 'Identificação Extrato',
    three_ds_active: '3Ds Ativo',
    min_three_ds: 'Valor Mínimo 3Ds',
    three_ds_value: 'Valor 3Ds',
    celcoin_fee_type: 'Tipo Taxa Pagamento de Contas',
    risk_classification: 'Risco',
    sale_plan_id: 'Plano de Venda'
  };