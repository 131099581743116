import React from 'react';
import { HStack } from '@chakra-ui/react';
import Text from '../../../components/Text';
import { FieldNames } from '../enums/FieldsNamesEnum';

const ErrorFields = ({ errors }) => {

	const errorKeys = Object.keys(errors);
	const errorsArray: string[] = errorKeys.reduce((acc: string[], errorKey) => {
		const errorValue = errors[errorKey];
		if (typeof errorValue === 'object' && errorValue !== null) {
			acc.push(...Object.keys(errorValue).map((subKey) => FieldNames[subKey] || subKey));
		} else {
			acc.push(FieldNames[errorKey] || errorKey);
		}
		return acc;
	}, []);

	return (
		<HStack w='100%' justifyContent='end' mt='10px' flexWrap='wrap'>
			<Text id='errors-label' fontSize='sm' color='red'>
				*Campos Incorretos:{' '}
			</Text>
			{errorsArray.length > 0 && (
				<Text id='errors' fontSize='sm' color='red'>
					{Array.from(new Set(errorsArray)).map((value, index, array) => (
						<span key={index}>
							{value}
							{index === array.length - 1 ? '.' : ', '}
						</span>
					))}
				</Text>
			)}
		</HStack>
	);
};

export default ErrorFields;