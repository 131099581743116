export const states = [
	{
		key: 'AC',
		value: 'AC',
		text: 'Acre',
	},
	{
		key: 'AL',
		value: 'AL',
		text: 'Alagoas',
	},
	{
		key: 'AP',
		value: 'AP',
		text: 'Amapá',
	},
	{
		key: 'AM',
		value: 'AM',
		text: 'Amazonas',
	},
	{
		key: 'BA',
		value: 'BA',
		text: 'Bahia',
	},
	{
		key: 'CE',
		value: 'CE',
		text: 'Ceará',
	},
	{
		key: 'DF',
		value: 'DF',
		text: 'Distrito Federal',
	},
	{
		key: 'ES',
		value: 'ES',
		text: 'Espírito Santo',
	},
	{
		key: 'GO',
		value: 'GO',
		text: 'Goiás',
	},
	{
		key: 'MA',
		value: 'MA',
		text: 'Maranhão',
	},
	{
		key: 'MT',
		value: 'MT',
		text: 'Mato Grosso',
	},
	{
		key: 'MS',
		value: 'MS',
		text: 'Mato Grosso do Sul',
	},
	{
		key: 'MG',
		value: 'MG',
		text: 'Minas Gerais',
	},
	{
		key: 'PA',
		value: 'PA',
		text: 'Pará',
	},
	{
		key: 'PB',
		value: 'PB',
		text: 'Paraíba',
	},
	{
		key: 'PR',
		value: 'PR',
		text: 'Paraná',
	},
	{
		key: 'PE',
		value: 'PE',
		text: 'Pernambuco',
	},
	{
		key: 'PI',
		value: 'PI',
		text: 'Piauí',
	},
	{
		key: 'RJ',
		value: 'RJ',
		text: 'Rio de Janeiro',
	},
	{
		key: 'RN',
		value: 'RN',
		text: 'Rio Grande do Norte',
	},
	{
		key: 'RS',
		value: 'RS',
		text: 'Rio Grande do Sul',
	},
	{
		key: 'RO',
		value: 'RO',
		text: 'Rondônia',
	},
	{
		key: 'RR',
		value: 'RR',
		text: 'Roraima',
	},
	{
		key: 'SC',
		value: 'SC',
		text: 'Santa Catarina',
	},
	{
		key: 'SP',
		value: 'SP',
		text: 'São Paulo',
	},
	{
		key: 'SE',
		value: 'SE',
		text: 'Sergipe',
	},
	{
		key: 'TO',
		value: 'TO',
		text: 'Tocantins',
	},
];

export const user_status = [
	{
		key: 'active',
		value: 'active',
		text: 'active',
	},
	{
		key: 'inactive',
		value: 'inactive',
		text: 'inactive',
	},
	{
		key: 'pending',
		value: 'pending',
		text: 'pending',
	},
];

export const type_account = [
	{
		key: 'current',
		value: 'current',
		text: 'Corrente',
	},
	{
		key: 'savings',
		value: 'savings',
		text: 'Poupança',
	},
];

export const absorb_costs = [
	{
		key: 'credit1x',
		value: 'credit1x',
		text: 'Crédito 1x',
	},
	{
		key: 'credit2x',
		value: 'credit2x',
		text: 'Crédito 2x',
	},
	{
		key: 'credit3x',
		value: 'credit3x',
		text: 'Crédito 3x',
	},
	{
		key: 'credit4x',
		value: 'credit4x',
		text: 'Crédito 4x',
	},
	{
		key: 'credit5x',
		value: 'credit5x',
		text: 'Crédito 5x',
	},
	{
		key: 'credit6x',
		value: 'credit6x',
		text: 'Crédito 6x',
	},
	{
		key: 'credit7x',
		value: 'credit7x',
		text: 'Crédito 7x',
	},
	{
		key: 'credit8x',
		value: 'credit8x',
		text: 'Crédito 8x',
	},
	{
		key: 'credit9x',
		value: 'credit9x',
		text: 'Crédito 9x',
	},
	{
		key: 'credit10x',
		value: 'credit10x',
		text: 'Crédito 10x',
	},
	{
		key: 'credit11x',
		value: 'credit11x',
		text: 'Crédito 11x',
	},
	{
		key: 'credit12x',
		value: 'credit12x',
		text: 'Crédito 12x',
	},
	{
		key: 'credit13x',
		value: 'credit13x',
		text: 'Crédito 13x',
	},
	{
		key: 'credit14x',
		value: 'credit14x',
		text: 'Crédito 14x',
	},
	{
		key: 'credit15x',
		value: 'credit15x',
		text: 'Crédito 15x',
	},
	{
		key: 'credit16x',
		value: 'credit16x',
		text: 'Crédito 16x',
	},
	{
		key: 'credit17x',
		value: 'credit17x',
		text: 'Crédito 17x',
	},
	{
		key: 'credit18x',
		value: 'credit18x',
		text: 'Crédito 18x',
	},
	{
		key: 'debit',
		value: 'debit',
		text: 'Débito',
	},
	{
		key: 'pix',
		value: 'pix',
		text: 'Pix',
	},
	{
		key: 'billet',
		value: 'billet',
		text: 'Boleto',
	},
	{
		key: 'billet_code',
		value: 'billet_code',
		text: 'Boleto Híbrido',
	},
	{
		key: 'pix_pos',
		value: 'pix_pos',
		text: 'Pix Pos',
	},
];

export const settlement_type = [
	{
		key: 'PIX',
		value: 'PIX',
		text: 'PIX',
	},
	{
		key: 'TED',
		value: 'TED',
		text: 'TED',
	},
];

export const seller_status = [
	{
		key: 'activated',
		value: 'activated',
		text: 'Ativo',
	},
	{
		key: 'inactive',
		value: 'inactive',
		text: 'Inativo',
	},
	{
		key: 'pending',
		value: 'pending',
		text: 'Pendente'
	},
	{
		key: 'activation_sent',
		value: 'activation_sent',
		text: 'Pendente de Ativação'
	}
];

export const seller_type = [
	{
		key: 'transaction',
		value: 'transaction',
		text: 'Transação',
	},
];
export const pos_status = [
	{
		key: 'active',
		value: 'active',
		text: 'active',
	},
	{
		key: 'broken',
		value: 'broken',
		text: 'broken',
	},
	{
		key: 'canceled',
		value: 'canceled',
		text: 'canceled',
	},
	{
		key: 'inventory',
		value: 'inventory',
		text: 'inventory',
	},
	{
		key: 'stock_for_repair',
		value: 'stock_for_repair',
		text: 'stock_for_repair',
	},
	{
		key: 'repair',
		value: 'repair',
		text: 'repair',
	},
	{
		key: 'lost',
		value: 'lost',
		text: 'lost',
	},
];

export const salePlanPaymentTypeEnum = [
	{
		key: 'credit',
		value: 'credit',
		text: 'Crédito',
	},
	{
		key: 'billet',
		value: 'billet',
		text: 'Boleto',
	},
	{
		key: 'pix',
		value: 'pix',
		text: 'Pix',
	},
	{
		key: 'debit',
		value: 'debit',
		text: 'Débito',
	},
];

export const payment_method = [
	{
		key: 'pos',
		value: 'pos',
		text: 'POS',
	},
	{
		key: 'online',
		value: 'online',
		text: 'Online',
	},
];

export const salePlanType = [
	{
		key: 'markup',
		value: 'markup',
		text: 'Markup',
	},
	{
		key: 'spread',
		value: 'spread',
		text: 'Spread',
	},
	{
		key: 'split',
		value: 'split',
		text: 'Split',
	},
];

export const pix_pos = [
	{
		key: 'true',
		value: 'true',
		text: 'Ativo',
	},
	{
		key: 'false',
		value: 'false',
		text: 'Inativo',
	},
];

export const status = [
	{
		key: 'activated',
		value: 'activated',
		text: 'Ativo',
		disable: false
	},
	{
		key: 'inactive',
		value: 'inactive',
		text: 'Inativo',
		disabled: false
	},
	{
		key: 'pending',
		value: 'pending',
		text: 'Pendente',
		disabled: true
	},
	{
		key: 'activation_sent',
		value: 'activation_sent',
		text: 'Pendente de Ativação',
		disabled: true
	}
];

export const accreditationStatus = [
	{
		key: 'active',
		value: 'active',
		text: 'Ativo (A)',
	},
	{
		key: 'active_and_ownership_change',
		value: 'active_and_ownership_change',
		text: 'Ativo/Troca de Titularidade (A/TT)',
	},
	{
		key: 'cancelled',
		value: 'cancelled',
		text: 'Cancelado (C)',
	},
	{
		key: 'inactive_by_ownership_change',
		value: 'inactive_by_ownership_change',
		text: 'Inativo por Troca de Titularidade (TT)',
	},
];

export const costPlanTypeStatus = [
	{
		key: 'paid',
		value: 'paid',
		text: 'Pago',
	},
	{
		key: 'pending',
		value: 'pending',
		text: 'Pendente',
	},
];

export const costOptionFeeType = [
	{
		key: 'fixed',
		value: 'fixed',
		text: 'Fixo',
	},
	{
		key: 'percent',
		value: 'percent',
		text: 'Porcentagem',
	},
];

export const billet_provider = [
	{
		key: 'adyen',
		value: 'adyen',
		text: 'Adyen',
	},
	{
		key: 'bradesco',
		value: 'bradesco',
		text: 'Bradesco',
	},
	{
		key: 'itau',
		value: 'itau',
		text: 'Itau',
	},
	{
		key: 'btg',
		value: 'btg',
		text: 'BTG',
	},
];

export const sale_status = [
	{ value: 'Pending', label: 'Pendente' },
	{ value: 'Succeeded', label: 'Aprovado' },
	{ value: 'Canceled', label: 'Cancelado' },
];

export const day_or_month = [
	{ value: 'month', label: 'Meses' },
	{ value: 'day', label: 'Dias' },
];

export const progression_information = [
	{ value: 'totalInReal', label: 'Total em reais' },
	{ value: 'averageTicket', label: 'Ticket médio' },
	{ value: 'numberTransactions', label: 'Número de transações' },
];

export const form_payments = {
	credit: 'credit',
	debit: 'debit',
	billet: 'billet',
	pix: 'pix',
	pending: 'pending',
};

export const default_merchant_subjects = [
	'Dashboard',
	'Sell',
	'POS',
	'SaleDetails',
	'MyAccount',
	'MyData',
	'MyPlan',
	'Support',
	'Billet',
	'Fees',
	'Link',
	'Financial',
	'SalesHistory',
	'Reports',
	'PaymentsHistory',
	'PaymentDetails',
	'Calendar',
	'Reports',
	'Users',
	'Services',
	'Customization',
	'CancelSale',
	'Reports',
	'Users',
	'Services',
	'Customization',
	'ChangeSeller',
	'Sellers',
	'SellerCredentials',
	'CreateSeller',
	'ProtocolsManagement',
	'Protocols',
	'ProtocolsHistory',
	'ProtocolsCustom',
	'EmailsCustom',
	'BillPaymentHistory',
];

export const default_merchant_seller_subjects = [
	'Dashboard',
	'Sell',
	'POS',
	'SaleDetails',
	'MyAccount',
	'MyData',
	'MyPlan',
	'Support',
	'Billet',
	'Fees',
	'Link',
	'Financial',
	'SalesHistory',
	'Reports',
	'PaymentsHistory',
	'PaymentDetails',
	'Calendar',
	'Reports',
	'Users',
	'Services',
	'Customization',
	'CancelSale',
	'Reports',
	'Users',
	'Services',
	'Customization',
	'ProtocolsManagement',
	'Protocols',
	'ProtocolsHistory',
	'ProtocolsCustom',
	'EmailsCustom',
];

export const pix_provider = [
	{
		key: 'adyen',
		value: 'adyen',
		text: 'Adyen',
	},
	{
		key: 'bradesco',
		value: 'bradesco',
		text: 'Bradesco',
	},
	{
		key: 'itau',
		value: 'itau',
		text: 'Itau',
	},
	{
		key: 'btg',
		value: 'btg',
		text: 'BTG',
	},
];

export const seller_assignment = [
	{
		key: 'civil',
		text: 'Civil',
	},
	{
		key: 'properties',
		text: 'Imóveis',
	},
	{
		key: 'note',
		text: 'Notas',
	},
	{
		key: 'legal_entity',
		text: 'Pessoa jurídica',
	},
	{
		key: 'protest',
		text: 'Protesto',
	},
];

export const responsible_type = [
	{
		key: 'support',
		text: 'Suporte',
	},
	{
		key: 'commercial',
		text: 'Comercial',
	},
];

export const risk_level = [
	{
		key: 'lower',
		value: 'lower',
		text: 'Baixo',
	},
	{
		key: 'medium',
		value: 'medium',
		text: 'Médio',
	},
	{
		key: 'high',
		value: 'high',
		text: 'Alto',
	},
];

export const billet_code = [
	{
		key: 'true',
		value: 'true',
		text: 'Ativo',
	},
	{
		key: 'false',
		value: 'false',
		text: 'Inativo',
	},
];

export const merchant_types = [
	{
		key: 'base',
		value: 'base',
		text: 'Base'
	},
	{
		key: 'central',
		value: 'central',
		text: 'Central'
	},
	{
		key: 'others',
		value: 'others',
		text: 'Outros'
	}
]

export const payment_provider = [
	{
		key: 'adyen',
		value: 'adyen',
		text: 'Adyen',
	},
	{
		key: 'rede',
		value: 'rede',
		text: 'Rede',
	}
];

export const months_of_year = [
	{ key: 'janeiro', value: 0, text: 'Janeiro' },
	{ key: 'fevereiro', value: 1, text: 'Fevereiro' },
	{ key: 'marco', value: 2, text: 'Março' },
	{ key: 'abril', value: 3, text: 'Abril' },
	{ key: 'maio', value: 4, text: 'Maio' },
	{ key: 'junho', value: 5, text: 'Junho' },
	{ key: 'julho', value: 6, text: 'Julho' },
	{ key: 'agosto', value: 7, text: 'Agosto' },
	{ key: 'setembro', value: 8, text: 'Setembro' },
	{ key: 'outubro', value: 9, text: 'Outubro' },
	{ key: 'novembro', value: 10, text: 'Novembro' },
	{ key: 'dezembro', value: 11, text: 'Dezembro' },
];

export const open_digital_account_file_type = [
	{
		key: 'CONTRATO_SOCIAL',
		value: 'CONTRATO_SOCIAL',
		text: 'Contrato Social',
	},
	{
		key: 'PROCURACAO',
		value: 'PROCURACAO',
		text: 'Procuração',
	},
	{
		key: 'ESTATUTO_SOCIAL',
		value: 'ESTATUTO_SOCIAL',
		text: 'Estatuto Social',
	},
	{
		key: 'REQUERIMENTO_EMPRESARIO',
		value: 'REQUERIMENTO_EMPRESARIO',
		text: 'Requerimento Empresário',
	},
	{
		key: 'BALANCO_DRE',
		value: 'BALANCO_DRE',
		text: 'Balanço DRE',
	},
	{
		key: 'ATA_ELEICAO_DIRETORIA',
		value: 'ATA_ELEICAO_DIRETORIA',
		text: 'Ata Eleição Diretoria',
	},
	{
		key: 'QUADRO_SOCIETARIO',
		value: 'QUADRO_SOCIETARIO',
		text: 'Quadro Societário',
	},
	{
		key: 'DOCUMENTO_FINANCEIRO',
		value: 'DOCUMENTO_FINANCEIRO',
		text: 'Documento Financeiro',
	},
	{
		key: 'CCMEI',
		value: 'CCMEI',
		text: 'CCMEI',
	},
];
