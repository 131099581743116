/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCustomization
 */
export interface ReportCustomization {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportCustomization
     */
    sales_history_report: Array<ReportCustomizationSalesHistoryReportEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportCustomization
     */
    settlement_batch_transaction_report: Array<ReportCustomizationSettlementBatchTransactionReportEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportCustomization
     */
    settlement_batch_sales_report: Array<ReportCustomizationSettlementBatchSalesReportEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportCustomization
     */
    bill_payment_sales_report: Array<ReportCustomizationBillPaymentSalesReportEnum>;
}

/**
* @export
* @enum {string}
*/
export enum ReportCustomizationSalesHistoryReportEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PayerDocument = 'payer_document',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    SplitDescription = 'split_description'
}/**
* @export
* @enum {string}
*/
export enum ReportCustomizationSettlementBatchTransactionReportEnum {
    OrderNumber = 'order_number',
    Id = 'id',
    SettlementStatus = 'settlement_status',
    Description = 'description',
    FormPayment = 'form_payment',
    Type = 'type',
    OriginalAmount = 'original_amount',
    Fee = 'fee',
    Amount = 'amount',
    Payer = 'payer',
    PayerDocument = 'payer_document',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    SellerName = 'seller_name',
    CardBrand = 'card_brand'
}/**
* @export
* @enum {string}
*/
export enum ReportCustomizationSettlementBatchSalesReportEnum {
    OrderNumber = 'order_number',
    Id = 'id',
    SettlementStatus = 'settlement_status',
    Description = 'description',
    FormPayment = 'form_payment',
    Type = 'type',
    OriginalAmount = 'original_amount',
    Fee = 'fee',
    Amount = 'amount',
    Payer = 'payer',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    SaleId = 'sale_id'
}/**
* @export
* @enum {string}
*/
export enum ReportCustomizationBillPaymentSalesReportEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    UpdatedAt = 'updated_at',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PayerDocument = 'payer_document',
    PayerEmail = 'payer_email',
    PayerPhone = 'payer_phone',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    Digitable = 'digitable',
    DueDate = 'due_date',
    Assignor = 'assignor',
    RecipientName = 'recipient_name',
    RecipientDocument = 'recipient_document'
}

export function ReportCustomizationFromJSON(json: any): ReportCustomization {
    return ReportCustomizationFromJSONTyped(json, false);
}

export function ReportCustomizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCustomization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales_history_report': json['sales_history_report'],
        'settlement_batch_transaction_report': json['settlement_batch_transaction_report'],
        'settlement_batch_sales_report': json['settlement_batch_sales_report'],
        'bill_payment_sales_report': json['bill_payment_sales_report'],
    };
}

export function ReportCustomizationToJSON(value?: ReportCustomization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales_history_report': value.sales_history_report,
        'settlement_batch_transaction_report': value.settlement_batch_transaction_report,
        'settlement_batch_sales_report': value.settlement_batch_sales_report,
        'bill_payment_sales_report': value.bill_payment_sales_report,
    };
}


