/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddSellerPixKeyDto
 */
export interface AddSellerPixKeyDto {
    /**
     * 
     * @type {string}
     * @memberof AddSellerPixKeyDto
     */
    digital_key_pix?: string;
}

export function AddSellerPixKeyDtoFromJSON(json: any): AddSellerPixKeyDto {
    return AddSellerPixKeyDtoFromJSONTyped(json, false);
}

export function AddSellerPixKeyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddSellerPixKeyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'digital_key_pix': !exists(json, 'digital_key_pix') ? undefined : json['digital_key_pix'],
    };
}

export function AddSellerPixKeyDtoToJSON(value?: AddSellerPixKeyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'digital_key_pix': value.digital_key_pix,
    };
}


