import React from 'react';
import { Flex, Select, Text } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';


type AlignmentSelectProps = {
	id: string;
	title: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};
const AlignmentSelect: React.FC<AlignmentSelectProps> = ({ id, title, value, onChange }) => {
  const theme = useTheme();

  return (
    <Flex flexDirection="column" width="50%">
      <Text id={`${id}-title`} fontSize="md">
        {title}
      </Text>
      <Select
        id={`${id}-select`}
        color={theme?.text_color || 'blackAlpha.600'}
        size="md"
        rounded="md"
        fontSize="md"
        bg="white"
        borderColor="darkGrey"
        name="saleDescriptionAligment"
        value={value}
        paddingY="2"
        onChange={(event) => onChange(event)}
      >
        <option value="left" key="left">
          Esquerda
        </option>
        <option value="center" key="center">
          Centro
        </option>
        <option value="right" key="right">
          Direita
        </option>
      </Select>
    </Flex>
  );
};

export default AlignmentSelect;
