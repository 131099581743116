import * as React from 'react';

import { GetSellerDto } from '../clients';

interface SellerContextData {
	currentSeller: GetSellerDto | undefined,
	updateCurrentSeller: (seller: GetSellerDto | undefined) => void;
}

const SellerContext = React.createContext<SellerContextData>({} as SellerContextData);

type SellerProviderProps = {
	children: React.ReactElement;
	seller?: GetSellerDto | undefined;
};

export const SellerProvider: React.FC<SellerProviderProps> = ({ children, ...rest }) => {
	const { seller: sellerProp } = rest;
	const [seller, setSeller] = React.useState<GetSellerDto | undefined>(rest?.seller);

	React.useEffect(() => {
		if (sellerProp) {
			setSeller(sellerProp);
		}		
	}, [sellerProp]);

	async function updateCurrentSeller(seller: GetSellerDto | undefined) {
		setSeller(seller);
	}

	return (
		<SellerContext.Provider value={{
			currentSeller: seller,
			updateCurrentSeller
		}}>
			{children}
		</SellerContext.Provider>
	);
};

export function useCurrentSeller() {
	const context = React.useContext(SellerContext);

	return context;
}
