import React from 'react';
import { Flex, Text, useBreakpointValue } from '@chakra-ui/react';

import { useSortable } from '@dnd-kit/sortable';
import { ReceiptAndroidDataTypeEnum } from '../../../clients';

type ReceiptCardProps = {
	id: string;
	type: ReceiptAndroidDataTypeEnum;
	aligment: string;
};

const labelDictionary: { [key: string]: string } = {
  seller_name: 'Nome do Estabelecimento',
  custom_text: 'Texto Personalizado',
  sale_description: 'Descrição da Venda',
	sale_status: 'Status da Venda',
	base_receipt: 'Recibo Básico',
	customer_data: 'Dados do Pagador'
};

const ReceiptCard: React.FC<ReceiptCardProps> = ({ id, type, aligment }) => {
	const { setNodeRef, attributes, listeners, isDragging, transform } = useSortable({ id });
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const getLabel = (key: string) => {
    return labelDictionary[key] || key;
  };

	const baseStyle = {
		border: '1px solid #ddd',
		borderRadius: 'md',
		transition: 'transform 200ms ease, opacity 200ms ease',
		opacity: isDragging ? 0.5 : 1,
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
	};

	const draggingStyle = {
		boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
		scale: isDragging ? 1.05 : 1,
	};
	return (
		<Flex
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			border='1px solid #ddd'
			borderRadius='md'
			p={4}
			m={2}
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			style={{ ...baseStyle, ...draggingStyle }}
		>
			<Text fontWeight='bold' fontSize={isMobile ? 'md' : 'lg'}>
				{getLabel(id)}
			</Text>
			{type !== ReceiptAndroidDataTypeEnum.BaseReceipt &&
				type !== ReceiptAndroidDataTypeEnum.FooterReceipt &&
				type !== ReceiptAndroidDataTypeEnum.Logo && 
				type !== ReceiptAndroidDataTypeEnum.CustomerData &&
				(
					<Text mt={2} fontSize='md'>
						Alinhamento: {aligment}
					</Text>
				)}
		</Flex>
	);
};

export default ReceiptCard;
