import React from 'react';
import { Flex, BreadcrumbItem, BreadcrumbLink, useBreakpointValue, Text, Box, Button, Image, Textarea } from '@chakra-ui/react';
import Breadcrumb from '../../components/Breadcrumb';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Paper from '../../containers/Paper';
import Title from '../../components/Title';
import FormControl from '../../components/Form/FormControl';
import Checkbox from '../../components/Checkbox';
import ReceiptCard from './components/ReceiptCard';
import { DndContext, DragEndEvent, useSensors, useSensor, MouseSensor, TouchSensor, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useCurrentSeller } from '../../contexts/SellerProvider';
import BackofficeAlertContainer from '../../containers/BackofficeAlertContainer';
import { useToasts } from 'react-toast-notifications';

import {
	SellersApi,
	UploadSellerReceiptAndroidLogoRequest,
	ReceiptAndroidData,
	ReceiptAndroidDataTypeEnum,
	GetSellerDto,
	UpdateSellerCustomReceiptRequest,
} from '../../clients';
import { getApiAuthConfig } from '../../services/api.service';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { AiOutlineDownload } from 'react-icons/ai';
import b64toBlob from 'b64-to-blob';
import AlignmentSelect from './components/AlingmentSelect';
import CropReceiptImageModal from './components/CropReceiptImageModal';
import { useAuth } from '../../contexts/AuthProvider';

const dummyData: ReceiptTypes = {
	customText: '',
	customTextAlignment: 'center',
	isCustomText: false,
	isLogo: false,
	isSaleDescription: false,
	isSellerName: false,
	logoUrl: '',
	saleDescriptionAlignment: 'center',
	sellerNameAlignment: 'center',
	isCustomerData: false,
	customerDataAlignment: 'center',
	cards: [
		{
			id: 'base_receipt',
			type: ReceiptAndroidDataTypeEnum.BaseReceipt,
			aligment: 'center',
		},
		{
			id: 'sale_status',
			type: ReceiptAndroidDataTypeEnum.FooterReceipt,
			aligment: 'center',
		},
	],
};

const allowedMimes = ['image/jpg', 'image/jpeg', 'image/png'];

type ReceiptTypes = {
	logoUrl: string;
	isSellerName: boolean;
	isSaleDescription: boolean;
	isLogo: boolean;
	sellerNameAlignment: string;
	saleDescriptionAlignment: string;
	customText: string;
	customTextAlignment: string;
	isCustomText: boolean;
	isCustomerData: boolean;
	customerDataAlignment: string;
	cards: ReceiptAndroidData[];
};

const ReceiptPosCustom: React.FC = () => {
	const { currentSeller, updateCurrentSeller } = useCurrentSeller();
	const { user, updateUser, isBackoffice } = useAuth();
	const isMobile = useBreakpointValue({ base: true, lg: false, md: true, sm: true });
	const [receipt, setReceipt] = React.useState<ReceiptTypes>(dummyData);
	const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [logoImages, setLogoImages] = React.useState<ImageListType>([]);
	const [isModalOpen, setModalOpen] = React.useState(false);
	const [croppedImage, setCroppedImage] = React.useState<string | undefined>(undefined);

	const apiConfig = getApiAuthConfig();
	const sellersApi = new SellersApi(apiConfig);
	const { addToast } = useToasts();

	const updateReceiptData = (seller: GetSellerDto | undefined) => {
		if (seller && seller.custom_android_receipt && seller.custom_android_receipt.receipt_data) {
			const cards = seller.custom_android_receipt.receipt_data || [];
			const sellerNameCard = cards.find((card) => card.id === 'seller_name');
			const saleDescriptionCard = cards.find((card) => card.id === 'sale_description');
			const customText = cards.find((card) => card.id === 'custom_text');
			const customerData = cards.find((card) => card.id === 'customer_data');
			const receiptInitialValues: ReceiptTypes = {
				logoUrl: seller.custom_android_receipt.logo_url || '',
				isSellerName: seller.custom_android_receipt.is_seller_name || false,
				isSaleDescription: seller.custom_android_receipt.is_sale_description || false,
				isLogo: seller.custom_android_receipt.is_logo || false,
				sellerNameAlignment: sellerNameCard?.aligment || 'center',
				saleDescriptionAlignment: saleDescriptionCard?.aligment || 'center',
				isCustomText: seller.custom_android_receipt.is_custom_text || false,
				customText: seller.custom_android_receipt.custom_text || '',
				customTextAlignment: customText?.aligment || 'center',
				isCustomerData: seller.custom_android_receipt.is_customer_data || false,
				customerDataAlignment: customerData?.aligment || 'center',
				cards,
			};

			setReceipt(receiptInitialValues);
		}
	};

	React.useEffect(() => {
		if (isBackoffice && !currentSeller?.custom_android_receipt) {
			setReceipt(dummyData);
		} else {
			updateReceiptData(currentSeller);
		}
	}, [currentSeller, isBackoffice]);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (receipt) {
			if (over) {
				const newCards = [...receipt?.cards];
				const fromIndex = newCards.findIndex((card) => card.id === active.id);
				const toIndex = newCards.findIndex((card) => card.id === over.id);

				if (fromIndex !== toIndex) {
					const [movedItem] = newCards.splice(fromIndex, 1);
					newCards.splice(toIndex, 0, movedItem);
				}

				setReceipt({ ...receipt, cards: newCards });
			}
		}
	};

	const formatAligment = (value: string) => {
		switch (value) {
			case 'center':
				return 'Centro';
			case 'left':
				return 'Esquerda';
			case 'right':
				return 'Direita';
			default:
				return 'Centro';
		}
	};

	const submitForm = async () => {
		if (currentSeller && receipt) {
			const requestParams: UpdateSellerCustomReceiptRequest = {
				sellerId: currentSeller.id,
				updateSellerReceiptDto: {
					custom_android_receipt: {
						...currentSeller.custom_android_receipt,
						is_seller_name: receipt.isSellerName,
						is_sale_description: receipt.isSaleDescription,
						is_logo: receipt.isLogo,
						is_custom_text: receipt.isCustomText,
						receipt_data: receipt.cards,
						custom_text: receipt.customText,
						is_customer_data: receipt.isCustomerData,
					},
				},
			};

			try {
				setIsSubmitting(true);

				const seller = await sellersApi.updateSellerCustomReceipt(requestParams);
				updateCurrentSeller(seller);

				if (!isBackoffice) {
					updateUser({
						...user,
						seller: {
							...user.seller,
							custom_android_receipt: seller.custom_android_receipt,
						},
					});
				}

				if (croppedImage) {
					let imageMimeType = 'image/jpeg';

					if (logoImages[0].file?.type && allowedMimes.includes(logoImages[0].file.type)) {
						imageMimeType = logoImages[0].file.type;
					}

					const base64Data = croppedImage.split(',')[1];
					const blob = b64toBlob(base64Data, imageMimeType);

					const payload1: UploadSellerReceiptAndroidLogoRequest = {
						sellerId: currentSeller?.id!,
						file: blob,
					};

					await sellersApi.uploadSellerReceiptAndroidLogo(payload1);
				}

				addToast('Personalização salva com sucesso', {
					appearance: 'success',
					autoDismiss: true,
				});

				setIsSubmitting(false);
			} catch (e) {
				addToast('Não foi possível salvar a personalização.', {
					appearance: 'error',
					autoDismiss: true,
				});

				setIsSubmitting(false);
			}
		}
	};

	const handlerSetCard = (
		id: string,
		type: ReceiptAndroidDataTypeEnum,
		alignment: string,
		shouldAdd: boolean,
		isCardActiveName: string,
		shouldUnshift: boolean = false
	) => {
		const upCards = [...receipt.cards];

		if (shouldAdd) {
			if (!upCards.some((card) => card.id === id)) {
				if (shouldUnshift) {
					upCards.unshift({
						id,
						type,
						aligment: alignment,
					});
				} else {
					upCards.push({
						id,
						type,
						aligment: alignment,
					});
				}
			}
		} else {
			const index = upCards.findIndex((card) => card.id === id);
			if (index !== -1) {
				upCards.splice(index, 1);
			}
		}

		setReceipt((prevState) => ({
			...prevState,
			cards: upCards,
			[isCardActiveName]: shouldAdd,
		}));
	};

	const handleAlignmentChange = (event: React.ChangeEvent<HTMLSelectElement>, cardId: string, stateKeyName: string) => {
		const value = event.target.value;
		const upCards = [...receipt.cards];

		const updateCardAlignment = (id: string, alignment: string) => {
			const cardIndex = upCards.findIndex((card) => card.id === id);
			if (cardIndex !== -1) {
				upCards[cardIndex] = {
					...upCards[cardIndex],
					aligment: alignment,
				};
			}
		};

		updateCardAlignment(cardId, value);

		setReceipt((prevState) => ({
			...prevState,
			[stateKeyName]: value,
			cards: upCards,
		}));
	};

	const handleChangeCustomText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value;
		setReceipt({ ...receipt, customText: value });
	};

	const onImageUploaded = (image: ImageListType) => {
		setLogoImages(image);
		setModalOpen(true);
	};

	const formatText = (text: string) => {
		const maxLineLength = 32;
		const lines: string[] = [];
		let line = '';

		text.split(' ').forEach((word) => {
			if (line.length + word.length + 1 <= maxLineLength) {
				line += (line.length ? ' ' : '') + word;
			} else {
				lines.push(line);
				line = word;
			}
		});

		if (line) {
			lines.push(line);
		}

		return lines.join('\n');
	};

	if (!currentSeller) {
		return <BackofficeAlertContainer />;
	}

	return (
		<Flex flexDirection='row' w='100%'>
			<Flex flexDirection={`column`} w='100%'>
				<Flex pb='8'>
					<Breadcrumb separator={<ChevronRightIcon />} color={`darkGrey`}>
						<BreadcrumbItem>
							<BreadcrumbLink href='#' fontSize='md'>
								Resumo
							</BreadcrumbLink>
						</BreadcrumbItem>

						<BreadcrumbItem>
							<BreadcrumbLink href='#' fontSize='md'>
								Minha Conta
							</BreadcrumbLink>
						</BreadcrumbItem>

						<BreadcrumbItem isCurrentPage>
							<BreadcrumbLink href='#' fontSize='md' fontWeight={`semibold`}>
								Personalização de Recibo
							</BreadcrumbLink>
						</BreadcrumbItem>
					</Breadcrumb>
				</Flex>
				<Title id='custom-receipt-title'>Personalização de Recibo</Title>
				<Paper id='receipt-custom-paper' p={6}>
					<Flex flexDirection={isMobile ? 'column' : 'row'} justifyContent={'flex-start'} alignItems='start' w='100%'>
						<Flex flexDirection='column' w={isMobile ? '100%' : '50%'} justifyContent='flex-start'>
							<DndContext
								sensors={sensors}
								collisionDetection={closestCenter}
								onDragEnd={handleDragEnd}
								modifiers={[restrictToVerticalAxis]}
							>
								<SortableContext items={receipt?.cards!} strategy={verticalListSortingStrategy}>
									{receipt.cards.map((card) => {
										if (card.type !== ReceiptAndroidDataTypeEnum.Logo) {
											return <ReceiptCard key={card.id} id={card.id} type={card.type} aligment={formatAligment(card.aligment)} />;
										}
										return null;
									})}
								</SortableContext>
							</DndContext>

							<FormControl p={4}>
								<Flex flexDirection={`column`}>
									<Checkbox
										id='seller-name-checkbox'
										name='seller-name'
										isChecked={receipt?.isSellerName}
										color={`darkGrey`}
										fontSize={`md`}
										fontWeight={`medium`}
										onChange={() =>
											handlerSetCard(
												'seller_name',
												ReceiptAndroidDataTypeEnum.SellerName,
												receipt.sellerNameAlignment,
												!receipt.isSellerName,
												'isSellerName',
												false
											)
										}
										paddingBottom={isMobile ? '4' : '1'}
										width={isMobile ? '100%' : ''}
										maxWidth={isMobile ? '300px' : ''}
										colorScheme='primary_checkbox'
									>
										Nome do estabelecimento
									</Checkbox>

									{receipt.isSellerName && (
										<AlignmentSelect
											id='sale-description-alignment'
											title='Alinhamento'
											value={receipt.sellerNameAlignment}
											onChange={(event) => handleAlignmentChange(event, 'seller_name', 'sellerNameAlignment')}
										/>
									)}
									<Checkbox
										id='customer-data-checkbox'
										name='customer-data'
										isChecked={receipt.isCustomerData}
										color={`darkGrey`}
										fontSize={`md`}
										fontWeight={`medium`}
										onChange={() =>
											handlerSetCard(
												'customer_data',
												ReceiptAndroidDataTypeEnum.CustomerData,
												receipt.customerDataAlignment,
												!receipt.isCustomerData,
												'isCustomerData',
												false
											)
										}
										paddingBottom={isMobile ? '4' : '1'}
										width={isMobile ? '100%' : ''}
										maxWidth={isMobile ? '300px' : ''}
										colorScheme='primary_checkbox'
									>
										Dados do pagador
									</Checkbox>
									<Checkbox
										id='sale-description-checkbox'
										name='sale-description'
										isChecked={receipt.isSaleDescription}
										color={`darkGrey`}
										fontSize={`md`}
										fontWeight={`medium`}
										onChange={() =>
											handlerSetCard(
												'sale_description',
												ReceiptAndroidDataTypeEnum.SaleDescription,
												receipt.saleDescriptionAlignment,
												!receipt.isSaleDescription,
												'isSaleDescription',
												false
											)
										}
										paddingBottom={isMobile ? '4' : '1'}
										width={isMobile ? '100%' : ''}
										maxWidth={isMobile ? '300px' : ''}
										colorScheme='primary_checkbox'
									>
										Descrição da venda
									</Checkbox>

									{receipt.isSaleDescription && (
										<AlignmentSelect
											id='sale-description-alignment'
											title='Alinhamento'
											value={receipt.saleDescriptionAlignment}
											onChange={(event) => handleAlignmentChange(event, 'sale_description', 'saleDescriptionAlignment')}
										/>
									)}

									<Checkbox
										id='logo-checkbox'
										name='logo-checkbox'
										isChecked={receipt?.isLogo}
										color={`darkGrey`}
										fontSize={`md`}
										fontWeight={`medium`}
										onChange={() => handlerSetCard('logo', ReceiptAndroidDataTypeEnum.Logo, 'center', !receipt.isLogo, 'isLogo', true)}
										paddingBottom={isMobile ? '4' : '1'}
										width={isMobile ? '100%' : ''}
										maxWidth={isMobile ? '300px' : ''}
										colorScheme='primary_checkbox'
									>
										Logo
									</Checkbox>

									{receipt && receipt.isLogo && (
										<Flex mb='6' justifyContent='start'>
											<ImageUploading value={logoImages} onChange={(value) => onImageUploaded(value)}>
												{({ onImageUpload, dragProps }) => (
													<>
														<Flex direction='column' justifyContent='center'>
															<Button
																id='upload-image-button'
																mb='2'
																onClick={onImageUpload}
																{...dragProps}
																rounded='sm'
																borderWidth={0.2}
																borderColor='gray.400'
																bgColor='white'
															>
																<AiOutlineDownload color='blue.700' size={16} />

																<Text id='upload-image-text' color='gray.700' ml='2'>
																	Importar imagem
																</Text>
															</Button>
															<Text id='max-width-text' fontSize='sm' textAlign='center'>
																Largura máxima: 384px
															</Text>
															<Text id='max-heigth-text' fontSize='sm' textAlign='center'>
																Altura máxima: 284px
															</Text>
														</Flex>
													</>
												)}
											</ImageUploading>
										</Flex>
									)}

									<Checkbox
										id='custom-text-checkbox'
										name='custom-text-checkbox'
										isChecked={receipt?.isCustomText}
										color={`darkGrey`}
										fontSize={`md`}
										fontWeight={`medium`}
										onChange={() =>
											handlerSetCard(
												'custom_text',
												ReceiptAndroidDataTypeEnum.CustomText,
												receipt.customTextAlignment,
												!receipt.isCustomText,
												'isCustomText',
												false
											)
										}
										paddingBottom={isMobile ? '4' : '1'}
										width={isMobile ? '100%' : ''}
										maxWidth={isMobile ? '300px' : ''}
										colorScheme='primary_checkbox'
									>
										Texto Personalizado
									</Checkbox>

									{receipt && receipt.isCustomText && (
										<AlignmentSelect
											id='custom-text-alignment'
											title='Alinhamento'
											value={receipt.customTextAlignment}
											onChange={(event) => handleAlignmentChange(event, 'custom_text', 'customTextAlignment')}
										/>
									)}

									{receipt && receipt.isCustomText && (
										<Flex flexDirection={`column`} width='50%'>
											<Textarea id='custom-text-area' name='note' onChange={handleChangeCustomText} value={receipt.customText} />
										</Flex>
									)}
								</Flex>
							</FormControl>
						</Flex>

						<Box w={['100%', '100%', '40%', '40%', '35%', '22%']} display='flex' justifyContent='center' alignItems='center' p='2'>
							<Box
								justifyContent='center'
								alignItems='center'
								w='100%'
								h='auto'
								p={4}
								borderWidth='1px'
								borderRadius='md'
								boxShadow='md'
								borderColor='gray.300'
								bg='white'
							>
								<Text fontSize='md' textAlign='center' fontWeight='bold'>
									Pré-visualização
								</Text>
								<Flex direction='column' mt={4}>
									{receipt &&
										receipt.cards.map((card) => {
											if (card.id === 'base_receipt') {
												return (
													<>
														<Flex py='1'></Flex>
														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>Data</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'01/01/2024'}</Text>
														</Flex>
														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>Hora</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'12:00:00'}</Text>
														</Flex>
														<Flex py='2'></Flex>
														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>Metodo de pagamento</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'pix'}</Text>
														</Flex>

														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>CET</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'0.20%'}</Text>
														</Flex>
														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>TXID</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'000000000000000000'}</Text>
														</Flex>

														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>TID</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'S1F2-000000000000000'}</Text>
														</Flex>
														<Flex py='2'></Flex>
														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>Valor destinado ao cartório</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'R$ 100,00'}</Text>
														</Flex>
														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>Valor total com acréscimo</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>{'R$ 100,20'}</Text>
														</Flex>
													</>
												);
											}
											if (card.id === 'seller_name' && receipt.isSellerName) {
												return (
													<>
														<Flex py='1'></Flex>
														<Text
															fontSize={isMobile ? 'sm' : 'md'}
															key={card.id}
															textAlign={card.aligment as any}
														>{`Nome do Estabelecimento`}</Text>
													</>
												);
											}
											if (card.id === 'sale_description' && receipt.isSaleDescription) {
												return (
													<>
														<Flex py='1'></Flex>
														<Text
															fontSize={isMobile ? 'sm' : 'md'}
															key={card.id}
															textAlign={card.aligment as any}
														>{`Descrição da Venda`}</Text>
													</>
												);
											}
											if (card.id === 'logo' && receipt.isLogo) {
												return (
													<>
														<Flex justifyContent='center' alignItems='center' width='100%'>
															<Image
																id='cropped-or-logo-image'
																src={croppedImage ? croppedImage : receipt.logoUrl}
																maxW='90%'
																maxH='5.625rem'
																borderWidth={1}
																borderColor='gray.400'
																style={{
																	filter: 'grayscale(100%)',
																}}
															/>
														</Flex>
														<Flex py='10'></Flex>
													</>
												);
											}
											if (card.id === 'sale_status') {
												return (
													<>
														<Flex py='1'></Flex>
														<Text fontSize={isMobile ? 'sm' : 'md'} textAlign='center'>
															Aprovada
														</Text>
													</>
												);
											}

											if (card.id === 'custom_text' && receipt.isCustomText) {
												return (
													<Flex width='100%' py='1'>
														<Text
															fontSize={isMobile ? 'sm' : 'md'}
															textAlign={receipt.customTextAlignment as any}
															whiteSpace='pre-wrap'
															wordWrap='break-word'
															width='100%'
														>
															{formatText(receipt.customText)}
														</Text>
													</Flex>
												);
											}

											if (card.id === 'customer_data' && receipt.isCustomerData) {
												return (
													<>
														<Flex py='1'></Flex>
														<Flex justifyContent='space-between'>
															<Text fontSize={isMobile ? 'sm' : 'md'}>Mastercard</Text>
															<Text fontSize={isMobile ? 'sm' : 'md'}>999999xxxxxx9999</Text>
														</Flex>
													</>
												);
											}
											return null;
										})}
								</Flex>
							</Box>
						</Box>
					</Flex>
					<Flex justifyContent={isMobile ? 'center' : 'center'} w={'100%'}>
						<Button
							id='save-customization-button'
							size='lg'
							type='submit'
							disabled={isSubmitting}
							bgColor={'primary'}
							isLoading={isSubmitting}
							width={isMobile ? '100%' : ''}
							maxWidth={isMobile ? '300px' : ''}
							onClick={submitForm}
						>
							<Text id='upload-image-text' color='white' ml='2'>
								Salvar Personalização
							</Text>
						</Button>
					</Flex>
				</Paper>
			</Flex>
			<CropReceiptImageModal
				isOpen={isModalOpen}
				imageToCrop={logoImages[0]}
				onClose={() => setModalOpen(false)}
				setCroppedImage={(croppedImg) => setCroppedImage(croppedImg)}
			/>
		</Flex>
	);
};

export default ReceiptPosCustom;
