/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSellerReportCustomizationDto
 */
export interface GetSellerReportCustomizationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSellerReportCustomizationDto
     */
    sales_history_report: Array<GetSellerReportCustomizationDtoSalesHistoryReportEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSellerReportCustomizationDto
     */
    settlement_batch_transaction_report: Array<GetSellerReportCustomizationDtoSettlementBatchTransactionReportEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSellerReportCustomizationDto
     */
    settlement_batch_sales_report: Array<GetSellerReportCustomizationDtoSettlementBatchSalesReportEnum>;
}

/**
* @export
* @enum {string}
*/
export enum GetSellerReportCustomizationDtoSalesHistoryReportEnum {
    OrderNumber = 'order_number',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    StatusDescription = 'status_description',
    Description = 'description',
    FormPaymentDescription = 'form_payment_description',
    TypeDescription = 'type_description',
    OriginalAmount = 'original_amount',
    FeeAmount = 'fee_amount',
    Amount = 'amount',
    PayerName = 'payer_name',
    PayerDocument = 'payer_document',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    Id = 'id',
    CardBrand = 'card_brand',
    CardNumber = 'card_number',
    CreatedByUserName = 'created_by_user_name',
    TerminalId = 'terminal_id',
    SellerName = 'seller_name',
    SellerDescription = 'seller_description',
    SplitDescription = 'split_description'
}/**
* @export
* @enum {string}
*/
export enum GetSellerReportCustomizationDtoSettlementBatchTransactionReportEnum {
    OrderNumber = 'order_number',
    Id = 'id',
    SettlementStatus = 'settlement_status',
    Description = 'description',
    FormPayment = 'form_payment',
    Type = 'type',
    OriginalAmount = 'original_amount',
    Fee = 'fee',
    Amount = 'amount',
    Payer = 'payer',
    PayerDocument = 'payer_document',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    SellerName = 'seller_name',
    CardBrand = 'card_brand'
}/**
* @export
* @enum {string}
*/
export enum GetSellerReportCustomizationDtoSettlementBatchSalesReportEnum {
    OrderNumber = 'order_number',
    Id = 'id',
    SettlementStatus = 'settlement_status',
    Description = 'description',
    FormPayment = 'form_payment',
    Type = 'type',
    OriginalAmount = 'original_amount',
    Fee = 'fee',
    Amount = 'amount',
    Payer = 'payer',
    CreatedAt = 'created_at',
    CreatedAtDate = 'created_at_date',
    PaymentDay = 'payment_day',
    PaymentDayDate = 'payment_day_date',
    ConfirmationDay = 'confirmation_day',
    SettlementDay = 'settlement_day',
    GeneratePayer = 'generate_payer',
    AcquirerReference = 'acquirer_reference',
    SaleId = 'sale_id'
}

export function GetSellerReportCustomizationDtoFromJSON(json: any): GetSellerReportCustomizationDto {
    return GetSellerReportCustomizationDtoFromJSONTyped(json, false);
}

export function GetSellerReportCustomizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSellerReportCustomizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales_history_report': json['sales_history_report'],
        'settlement_batch_transaction_report': json['settlement_batch_transaction_report'],
        'settlement_batch_sales_report': json['settlement_batch_sales_report'],
    };
}

export function GetSellerReportCustomizationDtoToJSON(value?: GetSellerReportCustomizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales_history_report': value.sales_history_report,
        'settlement_batch_transaction_report': value.settlement_batch_transaction_report,
        'settlement_batch_sales_report': value.settlement_batch_sales_report,
    };
}


