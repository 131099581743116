/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReceiptAndroidData,
    ReceiptAndroidDataFromJSON,
    ReceiptAndroidDataFromJSONTyped,
    ReceiptAndroidDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface SellerAndroidReceipt
 */
export interface SellerAndroidReceipt {
    /**
     * 
     * @type {boolean}
     * @memberof SellerAndroidReceipt
     */
    is_seller_name: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SellerAndroidReceipt
     */
    is_sale_description: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SellerAndroidReceipt
     */
    is_custom_text: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SellerAndroidReceipt
     */
    is_logo: boolean;
    /**
     * 
     * @type {Array<ReceiptAndroidData>}
     * @memberof SellerAndroidReceipt
     */
    receipt_data?: Array<ReceiptAndroidData>;
    /**
     * 
     * @type {string}
     * @memberof SellerAndroidReceipt
     */
    logo_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAndroidReceipt
     */
    logo_url?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAndroidReceipt
     */
    custom_text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SellerAndroidReceipt
     */
    is_customer_data: boolean;
}

export function SellerAndroidReceiptFromJSON(json: any): SellerAndroidReceipt {
    return SellerAndroidReceiptFromJSONTyped(json, false);
}

export function SellerAndroidReceiptFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerAndroidReceipt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_seller_name': json['is_seller_name'],
        'is_sale_description': json['is_sale_description'],
        'is_custom_text': json['is_custom_text'],
        'is_logo': json['is_logo'],
        'receipt_data': !exists(json, 'receipt_data') ? undefined : ((json['receipt_data'] as Array<any>).map(ReceiptAndroidDataFromJSON)),
        'logo_id': !exists(json, 'logo_id') ? undefined : json['logo_id'],
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'custom_text': !exists(json, 'custom_text') ? undefined : json['custom_text'],
        'is_customer_data': json['is_customer_data'],
    };
}

export function SellerAndroidReceiptToJSON(value?: SellerAndroidReceipt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_seller_name': value.is_seller_name,
        'is_sale_description': value.is_sale_description,
        'is_custom_text': value.is_custom_text,
        'is_logo': value.is_logo,
        'receipt_data': value.receipt_data === undefined ? undefined : ((value.receipt_data as Array<any>).map(ReceiptAndroidDataToJSON)),
        'logo_id': value.logo_id,
        'logo_url': value.logo_url,
        'custom_text': value.custom_text,
        'is_customer_data': value.is_customer_data,
    };
}


